import { Scene } from "phaser";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class Page6_0Scene extends Scene {
  constructor() {
    super({ key: "Page6_0Scene" });
  }

  init(data) {
    this.autoplay = data.autoplay;
    this.lang = data.lang;
    this.backScene = data.backScene;
  }

  COLOR_PRIMARY = 0x2563eb;
  COLOR_LIGHT = 0x4f4f4f;
  COLOR_DARK = 0x8f8f8f;

  NEXT_PAGE = "BreakScene";
  PRE_PAGE = "Page5Scene";
  IMG_FILE = "1 6-2.png";
  IMG_KEY = "img7";
  SFX_E_FILE = "7-e.wav";
  SFX_E_KEY = "sfx7E";
  SFX_Z_FILE = "7-z.wav";
  SFX_Z_KEY = "sfx7Z";
  TXT_E = "Hamochi broke his invention.";
  TXT_Z = "哦，不！不小心失手了。";

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });

    this.load.image(
      this.IMG_KEY,
      "/assets/mockups/ABC/book-1/images/" + this.IMG_FILE
    );
    this.load.audio(
      this.SFX_E_KEY,
      "/assets/mockups/ABC/book-1/audios/" + this.SFX_E_FILE
    );
    this.load.audio(
      this.SFX_Z_KEY,
      "/assets/mockups/ABC/book-1/audios/" + this.SFX_Z_FILE
    );

    // btns
    this.load.image(
      "imgBSN",
      "/assets/mockups/ABC/book-1/images/btns/skip-next.png"
    );
    this.load.image(
      "imgBSP",
      "/assets/mockups/ABC/book-1/images/btns/skip-previous.png"
    );
    this.load.image(
      "imgBVH",
      "/assets/mockups/ABC/book-1/images/btns/volume-high.png"
    );
    this.load.image(
      "imgBP",
      "/assets/mockups/ABC/book-1/images/btns/pause.png"
    );
    this.load.image(
      "imgBTSN",
      "/assets/mockups/ABC/book-1/images/btns/toggle-switch-on.png"
    );
    this.load.image(
      "imgBTSF",
      "/assets/mockups/ABC/book-1/images/btns/toggle-switch-off.png"
    );
    this.load.image(
      "imgBC",
      "/assets/mockups/ABC/book-1/images/btns/close.png"
    );
    this.load.image("imgBCG", "/assets/mockups/ABC/book-1/images/btns/cog.png");
  }

  create() {
    // state variables
    this.isPlaying = false;
    this.current = this.lang == 1 || this.lang == 2 ? "e" : "z";

    this.add.rectangle(245, 432, 480, 854, 0x000000, 0.5);
    this.add.rectangle(240, 427, 480, 854, 0xffffff);

    this.add
      .image(30, 30, "imgBC")
      .setScale(0.8)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.clickClose();
      });

    this.add
      .text(240, 30, "Autoplay            ON", {
        fontFamily: "roboto, sans-serif",
        fontSize: 14,
        color: "#000000",
      })
      .setOrigin(0.5);

    this.add
      .image(258, 30, this.autoplay ? "imgBTSN" : "imgBTSF")
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.toggleAutoplay();
      });

    this.add
      .image(450, 30, "imgBCG")
      .setScale(0.8)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.clickSetting();
      });

    this.add.image(240, 320, this.IMG_KEY).setScale(0.35);

    if (this.lang == 1 || this.lang == 2) {
      this.add
        .text(240, 550, this.TXT_E, {
          fontFamily: "roboto, sans-serif",
          fontSize: 20,
          color: "#000000",
          wordWrap: { width: 400 },
          align: "center",
        })
        .setOrigin(0.5);
    }

    if (this.lang == 1 || this.lang == 3) {
      this.add
        .text(
          240,
          this.lang == 3 ? 550 : 620,
          this.TXT_Z.replace(/，/g, "， ").replace(/。/g, "。 "),
          {
            fontFamily: "roboto, sans-serif",
            fontSize: 20,
            color: "#000000",
            wordWrap: { width: 400 },
            align: "center",
          }
        )
        .setOrigin(0.5);
    }

    this.add
      .image(160, 740, "imgBSP")
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.goPrev();
      });

    this.btnPause = this.add
      .image(240, 740, "imgBP")
      .setAlpha(0)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.pauseAudio();
      });
    this.btnPlay = this.add
      .image(240, 740, "imgBVH")
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.playAudio();
      });
    this.add
      .image(320, 740, "imgBSN")
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.goNext();
      });

    this.sfxE = this.sound.add(this.SFX_E_KEY);
    this.sfxZ = this.sound.add(this.SFX_Z_KEY);
    this.scene.on;

    if (this.autoplay) {
      this.time.delayedCall(500, () => {
        this.playAudio();
      });
    }

    if (this.backScene) {
      console.log("heen");
      this.pageBack(this.backScene);
    }
  }

  pauseAudio() {
    if (this.current == "e") {
      this.sfxE.pause();
    } else {
      this.sfxZ.pause();
    }
    this.btnPause.setAlpha(0);
    this.btnPlay.setAlpha(1);
  }

  playAudio() {
    if (this.isPlaying) {
      if (this.current == "e") {
        this.sfxE.resume();
      } else {
        this.sfxZ.resume();
      }
    } else {
      this.isPlaying = true;
      if (this.current == "e") {
        this.sfxE.play();
        this.sfxE.on("complete", () => {
          if (this.lang == 1) {
            this.current = "z";
            this.sfxZ.play();
            this.sfxZ.on("complete", () => {
              this.current = "e";
              this.stopAudio();
            });
          } else {
            this.stopAudio();
          }
        });
      } else {
        this.current = "z";
        this.sfxZ.play();
        this.sfxZ.on("complete", () => {
          this.stopAudio();
        });
      }
    }
    this.btnPause.setAlpha(1);
    this.btnPlay.setAlpha(0);
  }

  stopAudio() {
    this.isPlaying = false;
    this.btnPause.setAlpha(0);
    this.btnPlay.setAlpha(1);
    this.startCountdown();
  }

  toggleAutoplay() {
    this.time.removeAllEvents();
    this.sound.stopAll();
    this.scene.restart({ autoplay: !this.autoplay, lang: this.lang });
  }

  goNext(targetScene = this.NEXT_PAGE) {
    if (targetScene) {
      const width = this.sys.game.config.width;
      const height = this.sys.game.config.height;

      const renderTexture = this.add.renderTexture(0, 0, width, height);
      renderTexture.draw(this.children.list);

      const shadow = this.add.sprite(
        10,
        height / 2 + 10,
        renderTexture.texture.key
      );
      shadow.setOrigin(0, 0.5);
      shadow.setScale(1, 1);
      shadow.setTint(0x000000);
      shadow.setAlpha(0.5);

      const page = this.add.sprite(0, height / 2, renderTexture.texture.key);
      page.setOrigin(0, 0.5);
      page.setScale(1, 1);

      this.children.list.forEach((child) => {
        if (child !== page && child !== shadow) {
          child.setVisible(false);
        }
      });

      this.sound.stopAll();
      this.scene.launch(targetScene, {
        autoplay: this.autoplay,
        lang: this.lang,
      });
      this.scene.bringToTop();

      this.tweens.add({
        targets: [page, shadow],
        scaleX: { from: 1, to: 0 },
        duration: 800,
        onUpdate: (tween, target) => {
          if (target === shadow) {
            shadow.x = page.x + 10 * (1 - tween.progress);
            shadow.y = page.y + 10 * (1 - tween.progress);
            shadow.setAlpha(0.5 * (1 - tween.progress));
            shadow.setScale(1 * (1 + tween.progress));
          }
        },
        onComplete: () => {
          this.time.removeAllEvents();
          this.scene.stop();
          this.scene.bringToTop(targetScene);
        },
      });
    } else {
      this.clickClose();
    }
  }

  goPrev(targetScene = this.PRE_PAGE) {
    if (targetScene) {
      this.time.removeAllEvents();
      this.sound.stopAll();
      this.scene.launch(targetScene, {
        autoplay: this.autoplay,
        lang: this.lang,
        backScene: this.scene.key,
      });
    } else {
      this.clickClose();
    }
  }

  pageBack(targetScene) {
    this.scene.bringToTop();

    const width = this.sys.game.config.width;
    const height = this.sys.game.config.height;

    const renderTexture = this.add.renderTexture(0, 0, width, height);
    renderTexture.draw(this.children.list);

    const shadow = this.add.sprite(
      10,
      height / 2 + 10,
      renderTexture.texture.key
    );
    shadow.setOrigin(0, 0.5);
    shadow.setScale(1, 1);
    shadow.setTint(0x000000);
    shadow.setAlpha(0.5);

    const page = this.add.sprite(0, height / 2, renderTexture.texture.key);
    page.setOrigin(0, 0.5);
    page.setScale(0, 1);

    this.children.list.forEach((child) => {
      if (child !== page && child !== shadow) {
        child.setVisible(false);
      }
    });

    this.tweens.add({
      targets: [page, shadow],
      scaleX: { from: 0, to: 1 },
      duration: 500,
      onUpdate: (tween, target) => {
        if (target === shadow) {
          shadow.x = page.x + 10 * tween.progress;
          shadow.y = page.y + 10 * tween.progress;
          shadow.setAlpha(0.5 * tween.progress);
          shadow.setScale(1 * (2 - tween.progress));
        }
      },
      onComplete: () => {
        page.destroy();
        shadow.destroy();
        this.scene.stop(targetScene);
        this.children.list.forEach((child) => {
          child.setVisible(true);
        });
      },
    });
  }

  startCountdown() {
    let count = 3;
    let countdownTxt = this.add
      .text(380, 740, count, {
        fontFamily: "roboto, sans-serif",
        fontSize: 16,
        color: "#2563eb",
      })
      .setOrigin(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        countdown.remove();
        countdownTxt.destroy();
      });

    let countdown = this.time.addEvent({
      delay: 1000, // 1 second
      callback: () => {
        if (count > 0) {
          countdownTxt.setText(--count);
        } else {
          countdown.remove();
          countdownTxt.destroy();
          this.goNext();
        }
      },
      callbackScope: this,
      loop: true,
    });
  }

  clickClose() {
    this.time.removeAllEvents();
    this.sound.stopAll();
    this.scene.stop();
    this.scene.start("ChaptersScene");
  }

  clickSetting() {
    //
  }

  update() {}
}
