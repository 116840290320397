import Phaser from "phaser";
import CoverScene from "@/mockups/ABC/book-1/scenes/cover";
import Page1Scene from "@/mockups/ABC/book-1/scenes/page1";
import Page2Scene from "@/mockups/ABC/book-1/scenes/page2";
import Page3Scene from "@/mockups/ABC/book-1/scenes/page3";
import Page4Scene from "@/mockups/ABC/book-1/scenes/page4";
import Page5Scene from "@/mockups/ABC/book-1/scenes/page5";
import Page6_0Scene from "@/mockups/ABC/book-1/scenes/page6_0";
import Page6_1Scene from "@/mockups/ABC/book-1/scenes/page6_1";
import QuestionScene from "@/mockups/ABC/book-1/scenes/question";
import YippeeScene from "@/mockups/ABC/book-1/scenes/yippee";
import OopsScene from "@/mockups/ABC/book-1/scenes/oops";
import HeadingScene from "@/mockups/ABC/book-1/scenes/chapterHeding";
import LangScene from "@/mockups/ABC/book-1/scenes/selectLang";
import VocabScene from "@/mockups/ABC/book-1/scenes/vocabs";
import ChaptersScene from "@/mockups/ABC/book-1/scenes/chapters";
import ChaptersEndScene from "@/mockups/ABC/book-1/scenes/chaptersEnd";
import BreakScene from "@/mockups/ABC/book-1/scenes/break";

import DropShadowPipelinePlugin from "phaser3-rex-plugins/plugins/dropshadowpipeline-plugin.js";
import AwaitLoaderPlugin from "phaser3-rex-plugins/plugins/awaitloader-plugin.js";

function launch(parent) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xffffff,
    scale: {
      parent: parent,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    physics: {
      default: "arcade",
    },
    plugins: {
      global: [
        {
          key: "rexDropShadowPipeline",
          plugin: DropShadowPipelinePlugin,
          start: true,
        },
        {
          key: "rexAwaitLoader",
          plugin: AwaitLoaderPlugin,
          start: true,
        },
      ],
    },
    dom: {
      createContainer: true,
    },
    scene: [
      CoverScene,
      Page1Scene,
      Page2Scene,
      Page3Scene,
      Page4Scene,
      Page5Scene,
      Page6_0Scene,
      Page6_1Scene,
      QuestionScene,
      OopsScene,
      YippeeScene,
      HeadingScene,
      LangScene,
      VocabScene,
      ChaptersScene,
      ChaptersEndScene,
      BreakScene,
    ],
  });
}

export default launch;
export { launch };
