import { Scene } from "phaser";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class CoverScene extends Scene {
  constructor() {
    super({ key: "CoverScene" });
  }

  init(data) {
    console.log(data);
  }

  COLOR_PRIMARY = 0x4f4f4f;
  COLOR_LIGHT = 0x4f4f4f;
  COLOR_DARK = 0x8f8f8f;

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });
    this.load.image("img1", "/assets/mockups/ABC/book-1/images/0 1.png");
    this.load.image(
      "imgBook",
      "/assets/mockups/ABC/book-1/images/btns/bookx.png"
    );
  }

  create() {
    this.add
      .text(240, 180, "Hamochi", {
        fontFamily: "roboto, sans-serif",
        fontSize: 22,
        color: "#000000",
      })
      .setOrigin(0.5);

    this.add
      .text(240, 230, "The Dream Maker", {
        fontFamily: "roboto, sans-serif",
        fontSize: 42,
        color: "#000000",
      })
      .setOrigin(0.5);

    this.add
      .text(240, 290, "梦想实现家", {
        fontFamily: "roboto, sans-serif",
        fontSize: 42,
        color: "#000000",
      })
      .setOrigin(0.5);

    this.add
      .text(240, 340, "By Lim Shi Jian", {
        fontFamily: "roboto, sans-serif",
        fontSize: 20,
        color: "#000000",
      })
      .setOrigin(0.5);

    this.add.image(240, 520, "img1").setScale(0.3);

    this.add.image(240, 730, "imgBook").setInteractive({ useHandCursor: true });

    this.input.on("pointerdown", () => {
      this.nextPage();
    });
  }

  nextPage() {
    this.scene.stop();
    this.scene.start("ChaptersScene", {
      autoplay: true,
      lang: 1,
    });
  }

  clickClose() {
    //
  }

  clickSetting() {
    //
  }

  update() {}
}
