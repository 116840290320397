import { Scene } from "phaser";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class OopsScene extends Scene {
  constructor() {
    super({ key: "OopsScene" });
  }

  init(data) {
    this.autoplay = data.autoplay;
    this.lang = data.lang;
    this.backScene = data.backScene;
  }

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });
    this.load.image("img6", "/assets/mockups/ABC/book-1/images/1 6-0.png");
    this.load.image(
      "imgBC",
      "/assets/mockups/ABC/book-1/images/btns/close.png"
    );
    this.load.image("imgBCG", "/assets/mockups/ABC/book-1/images/btns/cog.png");
    this.load.image(
      "imgSTROT",
      "/assets/mockups/ABC/book-1/images/btns/star-outline.png"
    );
  }

  create() {
    this.add.rectangle(245, 432, 480, 854, 0x000000, 0.5);
    this.add.rectangle(240, 427, 480, 854, 0xffffff);

    this.add
      .text(240, 330, "Oops...", {
        fontFamily: "roboto, sans-serif",
        fontSize: 45,
        color: "#000000",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setOrigin(0.5);

    this.add.image(200, 400, "imgSTROT");
    this.add.image(240, 400, "imgSTROT");
    this.add.image(280, 400, "imgSTROT");
    this.time.delayedCall(1000, () => {
      this.goNext("Page6_0Scene");
    });
  }

  clickClose() {
    this.time.removeAllEvents();
    this.sound.stopAll();
    this.scene.stop();
    this.scene.start("ChaptersScene");
  }

  goNext(targetScene) {
    if (targetScene) {
      const width = this.sys.game.config.width;
      const height = this.sys.game.config.height;

      const renderTexture = this.add.renderTexture(0, 0, width, height);
      renderTexture.draw(this.children.list);

      const shadow = this.add.sprite(
        10,
        height / 2 + 10,
        renderTexture.texture.key
      );
      shadow.setOrigin(0);
      shadow.setScale(1, 1);
      shadow.setTint(0x000000);
      shadow.setAlpha(0.5);

      const page = this.add.sprite(0, height / 2, renderTexture.texture.key);
      page.setOrigin(0);
      page.setScale(1, 1);

      this.children.list.forEach((child) => {
        if (child !== page && child !== shadow) {
          child.setVisible(false);
        }
      });

      this.sound.stopAll();
      this.scene.launch(targetScene, {
        autoplay: this.autoplay,
        lang: this.lang,
      });
      this.scene.bringToTop();

      this.tweens.add({
        targets: [page, shadow],
        y: { from: 0, to: -900 },
        duration: 800,
        onUpdate: (tween, target) => {
          if (target === shadow) {
            shadow.x = page.x + 10 * (1 - tween.progress);
            shadow.y = page.y + 10 * (1 - tween.progress);
            shadow.setAlpha(0.5 * (1 - tween.progress));
            shadow.setScale(1 * (1 + tween.progress));
          }
        },
        onComplete: () => {
          this.time.removeAllEvents();
          this.scene.stop();
          this.scene.bringToTop(targetScene);
        },
      });
    } else {
      this.clickClose();
    }
  }

  clickSetting() {
    //
  }

  update() {}
}
