import { Scene } from "phaser";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class QuestionScene extends Scene {
  constructor() {
    super({ key: "QuestionScene" });
  }

  init(data) {
    this.autoplay = data.autoplay;
    this.lang = data.lang;
    this.backScene = data.backScene;
  }

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });
    this.load.image("img6", "/assets/mockups/ABC/book-1/images/1 6-0.png");
    this.load.image(
      "imgBC",
      "/assets/mockups/ABC/book-1/images/btns/close.png"
    );
    this.load.image("imgBCG", "/assets/mockups/ABC/book-1/images/btns/cog.png");
  }

  create() {
    this.add.rectangle(245, 432, 480, 854, 0x000000, 0.5);
    this.add.rectangle(240, 427, 480, 854, 0xffffff);

    this.qTxt = this.add
      .text(240, 400, "It’s Quiz Time!", {
        fontFamily: "roboto, sans-serif",
        fontSize: 42,
        color: "#000000",
      })
      .setOrigin(0.5);

    this.time.delayedCall(1000, () => {
      this.qTxt.destroy();

      this.add
        .image(30, 30, "imgBC")
        .setScale(0.8)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", () => {
          this.clickClose();
        });

      this.add
        .image(450, 30, "imgBCG")
        .setScale(0.8)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", () => {
          this.clickSetting();
        });

      this.add.image(240, 250, "img6").setScale(0.3);

      this.add
        .text(240, 450, "Where do Hamochi crafts items at night?", {
          fontFamily: "roboto, sans-serif",
          fontSize: 20,
          color: "#000000",
          wordWrap: { width: 400 },
          align: "center",
        })
        .setOrigin(0.5);

      this.option1Back = this.add
        .graphics()
        .lineStyle(2, 0x000000, 0.5)
        .strokeRoundedRect(120, 500, 240, 40, 10);

      this.option1Txt = this.add
        .text(140, 510, "(A) Village                        ", {
          fontFamily: "roboto, sans-serif",
          fontSize: 20,
          color: "#000000",
          wordWrap: { width: 400 },
          align: "center",
        })
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", () => {
          this.clickAnswer(1);
        });

      this.option2Back = this.add
        .graphics()
        .lineStyle(2, 0x000000, 0.5)
        .strokeRoundedRect(120, 550, 240, 40, 10);

      this.option2Txt = this.add
        .text(140, 560, "(B) School                         ", {
          fontFamily: "roboto, sans-serif",
          fontSize: 20,
          color: "#000000",
          wordWrap: { width: 400 },
          align: "center",
        })
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", () => {
          this.clickAnswer(2);
        });

      this.option3Back = this.add
        .graphics()
        .lineStyle(2, 0x000000, 0.5)
        .strokeRoundedRect(120, 600, 240, 40, 10);

      this.option3Txt = this.add
        .text(140, 610, "(C) Home                           ", {
          fontFamily: "roboto, sans-serif",
          fontSize: 20,
          color: "#000000",
          wordWrap: { width: 400 },
          align: "center",
        })
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", () => {
          this.clickAnswer(3);
        });
    });

    this.pageBack(this.backScene);
  }

  clickClose() {
    this.time.removeAllEvents();
    this.sound.stopAll();
    this.scene.stop();
    this.scene.start("ChaptersScene");
  }

  clickAnswer(ans) {
    this.option1Back.destroy();
    this.option2Back.destroy();
    this.option3Back.destroy();
    this.option1Txt.destroy();
    this.option2Txt.destroy();
    this.option3Txt.destroy();

    if (ans == 1) {
      this.option1Back = this.add
        .graphics()
        .fillStyle(0xff0000, 1)
        .fillRoundedRect(120, 500, 240, 40, 10);
    } else {
      this.option1Back = this.add
        .graphics()
        .lineStyle(2, ans == 3 ? 0x000000 : 0xff0000, 0.5)
        .strokeRoundedRect(120, 500, 240, 40, 10);
    }

    this.option1Txt = this.add.text(140, 510, "(A) Village", {
      fontFamily: "roboto, sans-serif",
      fontSize: 20,
      color: ans == 1 ? "#ffffff" : "#000000",
      wordWrap: { width: 400 },
      align: "center",
    });

    if (ans == 2) {
      this.option2Back = this.add
        .graphics()
        .fillStyle(0xff0000, 1)
        .fillRoundedRect(120, 550, 240, 40, 10);
    } else {
      this.option2Back = this.add
        .graphics()
        .lineStyle(2, ans == 3 ? 0x000000 : 0xff0000, 0.5)
        .strokeRoundedRect(120, 550, 240, 40, 10);
    }

    this.option2Txt = this.add.text(140, 560, "(B) School", {
      fontFamily: "roboto, sans-serif",
      fontSize: 20,
      color: ans == 2 ? "#ffffff" : "#000000",
      wordWrap: { width: 400 },
      align: "center",
    });

    this.option3Back = this.add
      .graphics()
      .fillStyle(0x2563eb, 1)
      .fillRoundedRect(120, 600, 240, 40, 10);

    this.option3Txt = this.add.text(140, 610, "(C) Home", {
      fontFamily: "roboto, sans-serif",
      fontSize: 20,
      color: "#ffffff",
      wordWrap: { width: 400 },
      align: "center",
    });
    // update colors
    // show message and OK btn
    console.log(ans);

    this.add.text(
      120,
      740,
      ans == 3 ? "Yes. You are correct." : "Oh.. no. You are incorrect.",
      {
        fontFamily: "roboto, sans-serif",
        fontSize: 18,
        color: "#000000",
        wordWrap: { width: 300 },
        align: "center",
      }
    );

    this.add
      .graphics()
      .fillStyle(0x2563eb, 1)
      .fillRoundedRect(350, 730, 100, 40, 5);

    this.add
      .text(350, 740, "      Next     ", {
        fontFamily: "roboto, sans-serif",
        fontSize: 20,
        color: "#ffffff",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.scene.start(ans == 3 ? "YippeeScene" : "OopsScene", {
          autoplay: this.autoplay,
          lang: this.lang,
        });
      });
  }

  pageBack(targetScene) {
    this.scene.bringToTop();

    const width = this.sys.game.config.width;
    const height = this.sys.game.config.height;

    const renderTexture = this.add.renderTexture(0, 0, width, height);
    renderTexture.draw(this.children.list);

    const shadow = this.add.sprite(
      10,
      -900,
      renderTexture.texture.key
    );
    shadow.setOrigin(0);
    shadow.setScale(1, 1);
    shadow.setTint(0x000000);
    shadow.setAlpha(0.5);

    const page = this.add.sprite(0, height / 2, renderTexture.texture.key);
    page.setOrigin(0);
    page.setScale(1);

    this.children.list.forEach((child) => {
      if (child !== page && child !== shadow) {
        child.setVisible(false);
      }
    });

    this.tweens.add({
      targets: [page, shadow],
      y: { from: -900, to: 0 },
      duration: 500,
      onUpdate: (tween, target) => {
        if (target === shadow) {
          shadow.x = page.x + 10 * tween.progress;
          shadow.y = page.y + 10 * tween.progress;
          shadow.setAlpha(0.5 * tween.progress);
          shadow.setScale(1 * (2 - tween.progress));
        }
      },
      onComplete: () => {
        page.destroy();
        shadow.destroy();
        this.scene.stop(targetScene);
        this.children.list.forEach((child) => {
          child.setVisible(true);
        });
      },
    });
  }

  clickSetting() {
    //
  }

  update() {}
}
