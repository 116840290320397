import { Scene } from "phaser";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class ChaptersEndScene extends Scene {
  constructor() {
    super({ key: "ChaptersEndScene" });
  }

  init(data) {
    this.autoplay = data.autoplay;
    this.lang = data.lang;
  }

  COLOR_PRIMARY = 0xb7b7b7;
  COLOR_LIGHT = 0x4f4f4f;
  COLOR_DARK = 0x8f8f8f;

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });

    // btns
    this.load.image(
      "imgBSN",
      "/assets/mockups/ABC/book-1/images/btns/skip-next.png"
    );
    this.load.image(
      "imgBSP",
      "/assets/mockups/ABC/book-1/images/btns/skip-previous.png"
    );
    this.load.image(
      "imgBVH",
      "/assets/mockups/ABC/book-1/images/btns/volume-high.png"
    );
    this.load.image(
      "imgBP",
      "/assets/mockups/ABC/book-1/images/btns/pause.png"
    );
    this.load.image(
      "imgBTSN",
      "/assets/mockups/ABC/book-1/images/btns/toggle-switch-on.png"
    );
    this.load.image(
      "imgBTSF",
      "/assets/mockups/ABC/book-1/images/btns/toggle-switch-off.png"
    );
    this.load.image(
      "imgBC",
      "/assets/mockups/ABC/book-1/images/btns/close.png"
    );
    this.load.image("imgBCG", "/assets/mockups/ABC/book-1/images/btns/cog.png");
    this.load.image(
      "imgSTROT",
      "/assets/mockups/ABC/book-1/images/btns/star-outline.png"
    );

    this.load.image(
      "imgLCK",
      "/assets/mockups/ABC/book-1/images/btns/lock-outline.png"
    );
    this.load.image(
      "imgLCKO",
      "/assets/mockups/ABC/book-1/images/btns/lock-open.png"
    );
    this.load.image(
      "imgBOOK",
      "/assets/mockups/ABC/book-1/images/btns/bookmark-outline.png"
    );
    this.load.image(
      "imgSTROT",
      "/assets/mockups/ABC/book-1/images/btns/star-outline.png"
    );
    this.load.image(
      "imgSTR",
      "/assets/mockups/ABC/book-1/images/btns/star.png"
    );
  }

  create() {
    this.add.rectangle(245, 432, 480, 854, 0x000000, 0.5);
    this.add.rectangle(240, 427, 480, 854, 0xffffff);

    this.add
      .image(30, 30, "imgBC")
      .setScale(0.8)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.clickClose();
      });

    this.add
      .text(240, 50, "The Dream Maker", {
        fontFamily: "roboto, sans-serif",
        fontSize: 30,
        color: "#000000",
      })
      .setOrigin(0.5);

    // this.add
    //   .text(240, 30, "Autoplay            ON", {
    //     fontFamily: "roboto, sans-serif",
    //     fontSize: 14,
    //     color: "#000000",
    //   })
    //   .setOrigin(0.5);

    // this.add
    //   .image(258, 30, this.autoplay ? "imgBTSN" : "imgBTSF")
    //   .setInteractive({ useHandCursor: true })
    //   .on("pointerdown", () => {
    //     this.toggleAutoplay();
    //   });

    this.add
      .image(450, 30, "imgBCG")
      .setScale(0.8)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.clickSetting();
      });

    const scrollablePanel = this.rexUI.add
      .scrollablePanel({
        x: 240,
        y: 480,
        width: 480,
        height: 750,

        scrollMode: 0, // 0: vertical, 1: horizontal

        panel: {
          child: this.createChapters(),
          mask: { padding: 1 },
        },

        // slider: {
        //   track: this.rexUI.add.roundRectangle(
        //     0,
        //     0,
        //     20,
        //     10,
        //     10,
        //     this.COLOR_DARK
        //   ),
        //   thumb: this.add.image(0, 0, "imgThumb"),
        // },

        space: {
          left: 30,
          right: 30,
          top: 0,
          bottom: 0,

          panel: {
            top: 0,
            bottom: 20,
            left: 0,
            right: 0,
          },
          //   slider: {
          //     top: 20,
          //     bottom: 20,
          //     left: 0,
          //     right: 0,
          //   },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.1,
        },

        // Other configurations...
      })
      .layout();

    scrollablePanel
      .setChildrenInteractive({ useHandCursor: true, cursor: "pointer" })
      .on("child.click", (child) => {
        if (child.isReadable) {
          this.scene.stop();
          this.scene.start("LangScene", {
            autoplay: true,
            lang: 1,
          });
        }
      });
  }

  createChapters() {
    var sizer = this.rexUI.add.sizer({
      orientation: "y",
      space: { bottom: 100, item: 5 },
    });

    let chapters = [
      {
        x: "Chapter 1",
        y: "Hamochi’s Daily Routine",
        m: true,
        n: true,
        o: false,
      },
      {
        x: "Chapter 2",
        y: "???",
        m: false,
        n: false,
        o: 1,
        p: true,
      },
      {
        x: "Chapter 3",
        y: "???",
        m: false,
        n: false,
        o: 2,
        p: false,
      },
      {
        x: "Chapter 4",
        y: "???",
        m: false,
        n: false,
        o: 3,
        p: false,
      },
    ];

    chapters.forEach((chapter) => {
      const container = this.rexUI.add.overlapSizer({
        space: { left: 0, right: 0, top: 10, bottom: 0 },
      });

      container.add(
        this.add.rectangle(0, 0, 400, 200, 0x000000).setOrigin(0.5)
      );
      container.add(
        this.add.rectangle(0, 0, 398, 198, 0xffffff).setOrigin(0.5),
        {
          key: undefined,
          align: "center",
          expand: false,
        }
      );

      container.add(this.add.image(0, 0, "imgBOOK"), {
        key: undefined,
        align: "left",
        padding: { left: 0, right: 0, top: -150, bottom: 0 },
        expand: false,
      });
      container.add(
        this.add.text(0, 0, chapter.x, {
          fontFamily: "roboto, sans-serif",
          fontSize: 28,
          color: "#000",
        }),
        {
          key: undefined,
          align: "left",
          padding: { left: 10, right: 0, top: -60, bottom: 0 },
          expand: false,
        }
      );
      container.add(
        this.add.text(0, 0, chapter.y, {
          fontFamily: "roboto, sans-serif",
          fontSize: 20,
          color: "#000",
        }),
        {
          key: undefined,
          align: "left",
          padding: { left: 12, right: 0, top: 0, bottom: 0 },
          expand: false,
        }
      );

      //   if (chapter.PlayerChapter.length > 0 && i != 0) {
      //     var containerStars = this.rexUI.add.sizer({
      //       orientation: "x",
      //       space: { item: 5, left: 260 },
      //     });

      //     containerStars.add(
      //       this.add
      //         .sprite(0, 0, "sptStar")
      //         .setScale(0.25)
      //         .setDepth(5)
      //         .setFrame(chapter.PlayerChapter[0].totalStar > 0 ? 1 : 0)
      //     );
      //     containerStars.add(
      //       this.add
      //         .sprite(0, 0, "sptStar")
      //         .setScale(0.25)
      //         .setDepth(5)
      //         .setFrame(chapter.PlayerChapter[0].totalStar > 1 ? 1 : 0)
      //     );
      //     containerStars.add(
      //       this.add
      //         .sprite(0, 0, "sptStar")
      //         .setScale(0.25)
      //         .setDepth(5)
      //         .setFrame(chapter.PlayerChapter[0].totalStar > 2 ? 1 : 0)
      //     );
      //     container.add(containerStars);
      //   }

      //   if (chapter.PlayerChapter.length > 0 || i == 0) {

      if (chapter.m) {
        container.add(this.add.image(0, 0, "imgSTR").setScale(0.8), {
          key: undefined,
          align: "right",
          padding: { left: 0, right: 10, top: 50, bottom: 0 },
          expand: false,
        });
        container.add(this.add.image(0, 0, "imgSTR").setScale(0.8), {
          key: undefined,
          align: "right",
          padding: { left: 0, right: 40, top: 50, bottom: 0 },
          expand: false,
        });
        container.add(this.add.image(0, 0, "imgSTR").setScale(0.8), {
          key: undefined,
          align: "right",
          padding: { left: 0, right: 70, top: 50, bottom: 0 },
          expand: false,
        });
      } else if (chapter.n) {
        container.add(this.add.image(0, 0, "imgSTROT").setScale(0.8), {
          key: undefined,
          align: "right",
          padding: { left: 0, right: 10, top: 50, bottom: 0 },
          expand: false,
        });
        container.add(this.add.image(0, 0, "imgSTROT").setScale(0.8), {
          key: undefined,
          align: "right",
          padding: { left: 0, right: 40, top: 50, bottom: 0 },
          expand: false,
        });
        container.add(this.add.image(0, 0, "imgSTROT").setScale(0.8), {
          key: undefined,
          align: "right",
          padding: { left: 0, right: 70, top: 50, bottom: 0 },
          expand: false,
        });
      }

      if (chapter.n) {
        const containerButton = this.rexUI.add.overlapSizer({
          space: { left: 288, right: 0, top: 140, bottom: 0 },
        });
        containerButton.add(
          this.add.rectangle(0, 0, 80, 40, 0x2563eb).setOrigin(0.5),
          {
            expand: false,
          }
        );

        containerButton.add(
          this.add
            .text(0, 0, "Read", {
              fontFamily: "roboto, sans-serif",
              fontSize: 42,
              color: "#fff",
            })
            .setScale(0.6),
          {
            expand: false,
          }
        );
        container.add(containerButton);
        container.isReadable = true;
      }

      if (chapter.o) {
        const containerButton = this.rexUI.add.overlapSizer({
          space: { left: 288, right: 0, top: 160, bottom: 0 },
        });
        containerButton.add(this.add.image(0, 0, "imgSTROT").setScale(0.4), {
          key: undefined,
          align: "right",
          padding: { left: 0, right: 40, top: 0, bottom: 0 },
          expand: false,
        });

        containerButton.add(
          this.add
            .text(0, 0, chapter.o, {
              fontFamily: "roboto, sans-serif",
              fontSize: 22,
              color: "#000",
            })
            .setScale(0.6),
          {
            align: "right",
            padding: { left: 0, right: 30, top: 0, bottom: 0 },
            expand: false,
          }
        );
        container.add(containerButton);
      }

      if (chapter.p) {
        const containerButton = this.rexUI.add.overlapSizer({
          space: { left: 310, right: 0, top: 100, bottom: 0 },
        });
        containerButton.add(this.add.image(0, 0, "imgLCKO"), {
          key: undefined,
          align: "right",
          padding: { left: 0, right: 22, top: 0, bottom: 0 },
          expand: false,
        });
        container.add(containerButton);
      } else if (chapter.o) {
        const containerButton = this.rexUI.add.overlapSizer({
          space: { left: 310, right: 0, top: 100, bottom: 0 },
        });
        containerButton.add(this.add.image(0, 0, "imgLCK"), {
          key: undefined,
          align: "right",
          padding: { left: 0, right: 22, top: 0, bottom: 0 },
          expand: false,
        });
        container.add(containerButton);
      }

      //   } else {
      //     const containerLock = this.rexUI.add.overlapSizer({
      //       space: { left: 280, right: 0, top: 0, bottom: 0 },
      //     });
      //     let lockImg = this.add.sprite(0, 0, "sptLock").setScale(0.8);

      //     if (
      //       i > 0 &&
      //       this.book.PlayerBook[0].totalStar >= chapter.minStarToUnlock &&
      //       this.chapters[i - 1].PlayerChapter.length > 0
      //     ) {
      //       containerLock.canUnlock = true;
      //       lockImg.setFrame(1);
      //     } else {
      //       lockImg.setFrame(0);
      //     }
      //     containerLock.add(lockImg, {
      //       expand: false,
      //       padding: { left: 0, right: 0, top: 0, bottom: 10 },
      //     });
      //     containerLock.add(this.add.image(0, 0, "imgStar").setScale(0.2), {
      //       expand: false,
      //       padding: { left: -15, right: 0, top: 90, bottom: 0 },
      //     });
      //     containerLock.add(
      //       this.add.text(0, 0, chapter.minStarToUnlock, {
      //         fontFamily: "MouseMemoirs, sans-serif",
      //         fontSize: 20,
      //         color: "#000",
      //       }),
      //       {
      //         expand: false,
      //         padding: { left: 20, right: 0, top: 90, bottom: 0 },
      //       }
      //     );

      //     container.add(containerLock);

      //     if (
      //       i > 0 &&
      //       this.book.PlayerBook[0].totalStar >= chapter.minStarToUnlock &&
      //       this.chapters[i - 1].PlayerChapter.length > 0
      //     ) {
      //       container.canUnlock = true;
      //     }
      //   }

      sizer.add(container);
    });

    return sizer;
  }

  goStory(selectedChapter) {
    this.scene.stop("ChaptersEndScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);

    this.scene.start("ChapterIntroScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapter: selectedChapter,
      book: this.book,
      books: this.books,
      chapters: this.chapters,
    });
  }

  unlockChapter(selectedChapter) {
    console.log(selectedChapter);
    this.scene.stop("ChaptersEndScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);

    this.scene.start("ChapterUnlockScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapter: selectedChapter,
      book: this.book,
      books: this.books,
      chapters: this.chapters,
    });
  }

  infoDialog(txt) {
    let darkx = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0.7)
      .setDepth(11)
      .setScrollFactor(0);
    let panelx = this.add
      .image(240, 400, "imgOptionPanel")
      .setDepth(11)
      .setScale(0.6, 0.4)
      .setScrollFactor(0);

    let okBtn = this.add
      .image(240, 500, "imgOkBtn")
      .setScale(0.5)
      .setDepth(11)
      .setScrollFactor(0);
    let okBtnTxt = this.add
      .text(240, 500, "OK", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 20,
        color: "#000",
      })
      .setOrigin(0.5)
      .setDepth(11)
      .setScrollFactor(0);

    let infoTxt = this.add
      .text(240, 320, txt, {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 18,
        color: "#000",
        wordWrap: { width: 200 },
        align: "center",
      })
      .setOrigin(0.5)
      .setDepth(11)
      .setScrollFactor(0);

    let infoImg = this.add
      .image(240, 410, "imgFreezeMochi")
      .setScale(0.15)
      .setDepth(11)
      .setScrollFactor(0);

    okBtn.setInteractive({ useHandCursor: true }).on(
      "pointerdown",
      () => {
        infoTxt.destroy();
        okBtnTxt.destroy();
        panelx.destroy();
        darkx.destroy();
        infoImg.destroy();
        okBtn.destroy();
      },
      this
    );
  }

  clickClose() {
    this.time.removeAllEvents();
    this.sound.stopAll();
    this.scene.stop();
    this.scene.start("CoverScene");
  }

  clickSetting() {
    //
  }

  toggleAutoplay() {
    this.time.removeAllEvents();
    this.sound.stopAll();
    this.scene.restart({ autoplay: !this.autoplay, lang: this.lang });
  }

  update() {
    //
  }
}
