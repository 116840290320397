import { Scene } from "phaser";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class VocabScene extends Scene {
  constructor() {
    super({ key: "VocabScene" });
  }

  init(data) {
    this.autoplay = data.autoplay;
    this.lang = data.lang;
    this.backScene = data.backScene;
  }

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });
    this.load.image("img2", "/assets/mockups/ABC/book-1/images/0 2.png");
    this.load.image("img3", "/assets/mockups/ABC/book-1/images/1 1.png");
    this.load.image("img4", "/assets/mockups/ABC/book-1/images/1 2.png");
    this.load.image(
      "imgBC",
      "/assets/mockups/ABC/book-1/images/btns/close.png"
    );
    this.load.image("imgBCG", "/assets/mockups/ABC/book-1/images/btns/cog.png");
  }

  create() {
    this.add.rectangle(245, 432, 480, 854, 0x000000, 0.5);
    this.add.rectangle(240, 427, 480, 854, 0xffffff);


    this.add
      .image(30, 30, "imgBC")
      .setScale(0.8)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.clickClose();
      });

    this.add
      .image(450, 30, "imgBCG")
      .setScale(0.8)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.clickSetting();
      });

    

    this.add
      .text(240, 200, "Chapter Completed", {
        fontFamily: "roboto, sans-serif",
        fontSize: 40,
        color: "#00000",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setOrigin(0.5);

      this.add
      .text(240, 280, "You’ve found", {
        fontFamily: "roboto, sans-serif",
        fontSize: 18,
        color: "#00000",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setOrigin(0.5);

      this.add
      .text(240, 320, "3 Vocabularies!", {
        fontFamily: "roboto, sans-serif",
        fontSize: 22,
        color: "#00000",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setOrigin(0.5);

      this.add.image(100, 450, "img3").setScale(0.1);
      this.add.image(240, 450, "img4").setScale(0.1);
      this.add.image(380, 450, "img2").setScale(0.1);


    this.add
      .graphics()
      .fillStyle(0x2563eb, 1)
      .fillRoundedRect(170, 550, 120, 40, 10);

    this.add
      .text(170, 560, "        Yeah        ", {
        fontFamily: "roboto, sans-serif",
        fontSize: 20,
        color: "#ffffff",
        wordWrap: { width: 400 },
        align: "center",
      })
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", () => {
        this.clickClose();
      });
  }

  clickClose() {
    this.time.removeAllEvents();
    this.sound.stopAll();
    this.scene.stop();
    this.scene.start("ChaptersEndScene");
  }

  pageBack(targetScene) {
    this.scene.bringToTop();

    const width = this.sys.game.config.width;
    const height = this.sys.game.config.height;

    const renderTexture = this.add.renderTexture(0, 0, width, height);
    renderTexture.draw(this.children.list);

    const shadow = this.add.sprite(10, -900, renderTexture.texture.key);
    shadow.setOrigin(0);
    shadow.setScale(1, 1);
    shadow.setTint(0x000000);
    shadow.setAlpha(0.5);

    const page = this.add.sprite(0, height / 2, renderTexture.texture.key);
    page.setOrigin(0);
    page.setScale(1);

    this.children.list.forEach((child) => {
      if (child !== page && child !== shadow) {
        child.setVisible(false);
      }
    });

    this.tweens.add({
      targets: [page, shadow],
      y: { from: -900, to: 0 },
      duration: 500,
      onUpdate: (tween, target) => {
        if (target === shadow) {
          shadow.x = page.x + 10 * tween.progress;
          shadow.y = page.y + 10 * tween.progress;
          shadow.setAlpha(0.5 * tween.progress);
          shadow.setScale(1 * (2 - tween.progress));
        }
      },
      onComplete: () => {
        page.destroy();
        shadow.destroy();
        this.scene.stop(targetScene);
        this.children.list.forEach((child) => {
          child.setVisible(true);
        });
      },
    });
  }

  clickSetting() {
    //
  }

  update() {}
}
