<template>
  <div
    ref="gameContainer"
    style="
      max-width: 480px;
      padding: 0;
      max-height: 100vh !important;
      margin: auto;
    "
  ></div>
</template>

<script>
import { mapState } from "vuex";
const Book = require("@/mockups/ABC/book-1/main");

export default {
  name: "Book1",
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    this.initializeGame();
  },
  methods: {
    initializeGame() {
      this.gameInstance = Book.launch(this.$refs.gameContainer);
      this.gameInstance.scene.start("CoverScene", {
        env: this.env,
      });
    },
  },
  beforeDestroy() {
    if (this.gameInstance) {
      this.gameInstance.destroy(true);
    }
  },
};
</script>